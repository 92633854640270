<template>
    <div class="block block-methods" v-if="currentProduct" >
        <div class="method-bg_img" :key="'bg'+activeSlideProd">
            <img v-if="currentProduct.bg_img_bottom" class="bg_img bg_img_bottom" :src="currentProduct.bg_img_bottom" alt="">
            <img v-if="currentProduct.bg_img_left" class="bg_img bg_img_left" :src="currentProduct.bg_img_left" alt="">
            <img v-if="currentProduct.bg_img_top_big" class="bg_img bg_img_top_big" :src="currentProduct.bg_img_top_big" alt="">
            <img v-if="currentProduct.bg_img_top_small" class="bg_img bg_img_top_small" :src="currentProduct.bg_img_top_small" alt="">
        </div>
        <div class="content-box method-wr" :key="activeSlideProd">
            <div >
                <h2 class="title" :class="{'new-con': true}" v-html="currentProduct.title_1"></h2>
                <div class="title-bottom">
                    <img v-for="item in currentProduct.title_imgs" :key="item" :src="item" alt="">
                    <h2 class="title" v-html="currentProduct.title_2"></h2>
                </div>
                <p class="description descr2" v-html="currentProduct.description"></p>
            </div>
            <div class="method">
                <div class="method__title" v-html="cookings[0].method_title"></div>
                <!-- Способ приготовления -->
                <div class="method__line">
                    <!-- Шаги -->
                    <div v-for="(item,index) in cookings[this.activeSlideProd].steps" :key="'step'+index" class="method__box">
                        <div class="method__img">
                             <span class="method__number-box"><span class="method__number">{{ index + 1 }}</span></span>
                              <img :src="item.image" alt="">
                        </div>
                        <div class="method__text" v-html="item.text"></div>
                    </div>
                </div>
            </div>
            <div class="line-block">
                <router-link tag="button" v-on:click.native="goToPage()" :to="`/${langContent}/recipes`" class="button button-next-slide button_brown" v-html="currentProduct.btn_next"></router-link>
                <router-link tag="button" v-on:click.native="goToPage()" :to="`/${langContent}/recipes`" class="button button-down button_white">
                    <i class="far fa-angle-down"></i>
                </router-link>
            </div>
        </div>
        <div class="prod-slider" >
            <div
                v-if="cookings.length > 1"
                class="prod-slider__button">
                <button @click="productСarouselPrev()"  class="slider-button" id="prod-slider-button-prev"><i class="far fa-angle-left"></i></button>
                <button @click="productСarouselNext()" class="slider-button" id="prod-slider-button-next"><i class="far fa-angle-right"></i></button>
            </div>
            
            <div class="prod-slider__content" v-if="cookings.length > 1">
                <div class="prod-slider-slide" v-for="(img, index) in cookings" :key="'img'+index" :ref="'slide'+index" :class="{'active': index === activeSlideProd, slidePrev: isSlidePrev && index === activeSlideProd, scaleActive : img.isScaleActive,}">
                    <img :src="img.image" class="prod-slider__img">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import VueAxios from 'vue-axios'
import { HTTP, Routes } from '../API'
    export default {
        props:{
            langContent:{type: String, default: 'ru'},
        },
        data() {
            return {
                srollPageVal: 'slide-fadeTop',
                removeScrollButton: true,
                slideNext: false,
                slidePrev: false,
                slide: null,
                slideFlag: true,
                cookings: [],
                currentProduct: null,
                activeSlideProd: 0,
                slideActive: false,
                isScaleActive: false,
                isSlidePrev: false,

                pageTitle: null,
                steps: [],
                cookingСhocolate:[],
                cookingStrawberry: [],
                nextButton: null,
                prodYellow: null,
                prodCocoa: null,
            }
        },
        computed: {

        },
        methods:{
            goToPage(){
                this.$emit('scrollPage',this.srollPageVal)
            },
            // productСarousel(){
            //     if(this.slideFlag === true){
            //         this.slideFlag = false
            //         for (let index = 0; index < this.slide.length; index++) {
            //             let indexSlide = this.slide[index]
            //             console.log('indexSlide: ', indexSlide);
            //             let activeSlide = indexSlide.classList.contains('active')
            //             if(activeSlide === true){
            //                 indexSlide.classList.add('scaleActive')
            //                 setTimeout(()=>{
            //                     this.activeSlide()
            //                     indexSlide.classList.remove('scaleActive')
            //                     this.slideFlag = true
            //                 },850)
            //                 this.nextSlide()
            //             }
            //         }
            //     }
            // },
            // nextSlide(){
            //     this.slide[0].classList.remove('slideNext')
            //     this.slide[1].classList.remove('slidePrev')
            //     if(this.slideNext === false){
            //         this.slideNext = true
            //         setTimeout(()=>{
            //             this.slidePrev = false
            //         },850)
            //     }else{
            //         this.slidePrev = true
            //         setTimeout(()=>{
            //             this.slideNext = false
            //         },850)
            //     }
            // },
            // activeSlide(){
            //     console.log('qqq');
            //     this.slide = document.getElementsByClassName('prod-slider-slide')
            //     if(this.slide[1].classList.contains('active') === false){
            //         console.log('111');
            //         this.slide[1].classList.add('active')
            //         this.slide[0].classList.remove('active')
            //     } else {
            //         console.log('22');
            //         this.slide[1].classList.remove('active')
            //         this.slide[0].classList.add('active')
            //     }
            // },
            activeSlidePrev(){
                this.isSlidePrev = true;

                if (this.activeSlideProd === 1) {
                    let indexSlide = this.$refs['slide2'][0]
                    let activeSlide = indexSlide.classList.contains('active')
                    if(activeSlide === true){
                        this.cookings[2].isScaleActive = true
                        setTimeout(()=>{
                            this.cookings[2].isScaleActive = false
                            this.$forceUpdate();
                        },850)
                    }
                }
                if (this.activeSlideProd === 2) {
                    let indexSlide = this.$refs['slide0'][0]
                    let activeSlide = indexSlide.classList.contains('active')
                    if(activeSlide === true){
                        this.cookings[0].isScaleActive = true
                        setTimeout(()=>{
                            this.cookings[0].isScaleActive = false
                            this.$forceUpdate();
                        },850)
                    }

                }
                if (this.activeSlideProd === 0) {
                    let indexSlide = this.$refs['slide1'][0]
                    let activeSlide = indexSlide.classList.contains('active')
                    if(activeSlide === true){
                        this.cookings[1].isScaleActive = true
                        setTimeout(()=>{
                            this.cookings[1].isScaleActive = false
                            this.$forceUpdate();
                        },850)
                    }
                }
            },
            activeSlideNext(){
                this.isSlidePrev = true;

                if (this.activeSlideProd === 1) {
                    let indexSlide = this.$refs['slide0'][0]
                    let activeSlide = indexSlide.classList.contains('active')
                    if(activeSlide === true){
                        this.cookings[0].isScaleActive = true
                        setTimeout(()=>{
                            this.cookings[0].isScaleActive = false
                            this.$forceUpdate();
                        },850)
                    }
                }
                if (this.activeSlideProd === 2) {
                    let indexSlide = this.$refs['slide1'][0]
                    let activeSlide = indexSlide.classList.contains('active')
                    if(activeSlide === true){
                        this.cookings[1].isScaleActive = true
                        setTimeout(()=>{
                            this.cookings[1].isScaleActive = false
                            this.$forceUpdate();
                        },850)
                    }

                }
                if (this.activeSlideProd === 0) {
                    let indexSlide = this.$refs['slide2'][0]
                    let activeSlide = indexSlide.classList.contains('active')
                    if(activeSlide === true){
                        this.cookings[2].isScaleActive = true
                        setTimeout(()=>{
                            this.cookings[2].isScaleActive = false
                            this.$forceUpdate();
                        },850)
                    }
                }
            },
            productСarouselPrev() {
                if (this.activeSlideProd === 0) {
                    this.activeSlideProd = this.cookings.length - 1;
                } else if (this.activeSlideProd > 0) {
                    this.activeSlideProd -= 1;
                }
                this.activeSlidePrev();
                this.currentProduct = this.cookings[this.activeSlideProd]
            },
            productСarouselNext() {
                if (this.activeSlideProd === this.cookings.length - 1) {
                    this.activeSlideProd = 0;
                } else if (this.activeSlideProd < this.cookings.length - 1) {
                    this.activeSlideProd += 1;
                }
                this.activeSlideNext();
                this.currentProduct = this.cookings[this.activeSlideProd]
            },
            getContent(){
                HTTP.get(Routes.cooking)
                    .then(  response => {
                        if(response.data.err === 0){
                            this.cookings = response.data.cooking;
                            this.currentProduct = this.cookings[this.activeSlideProd],
                            this.cookings.forEach(el => el.isScaleActive = false)
                            this.slide = document.getElementsByClassName('prod-slider-slide')
                            // this.slide[0].classList.remove('slidePrev')
                            // this.pageTitle = response.data.cooking[1].method_title
                            // this.steps = response.data.cooking[this.activeSlideProd].steps
                            // this.cookingStrawberry = response.data.cooking[0]
                            // console.log('response.data: ', response.data);
                            // this.cookingСhocolate = response.data.cooking[1]
                            this.nextButton = response.data.cooking[0].btn_next 
                            // this.prodYellow = response.data.cooking[1].image
                            // this.prodCocoa = response.data.cooking[0].image
                            setTimeout(() => {
                                this.$emit('buttonNextScroll', this.nextButton)
                            }, 0)
                        }
                })
                .catch( error => {
                    console.log(error);
                });
            }
        },
        mounted() {
            // this.slide && this.cookings.length <= 2 && this.activeSlide();
            this.getContent();
            

        },
        beforeCreate(){
            this.removeScrollButton = true
            this.$emit('removeButtonScrollFooter',this.removeScrollButton)
        },
    }
</script>
